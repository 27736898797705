<template>
  <UIDropdownActions :data :class="mainClasses">
    <template #default="{ toggle }">
      <UIButton
        v-bind="{ ...$attrs, full }"
        :leading="PlusIcon"
        :hide-label-to-sm="!showLabelOnMobile"
        type="button"
        @click="toggle()"
      />
    </template>
  </UIDropdownActions>
</template>

<script setup lang="ts">
import { computed, h } from 'vue'
import { Placement } from 'floating-vue'

import { LinkIcon, PlusIcon } from '@heroicons/vue/20/solid'
import { UIServiceIcon } from '@ui/core'
import { UIButton } from '@ui/buttons'
import { UIDropdownActions } from '@ui/dropdowns'

type Props = {
  placement?: Placement
  hideIcon?: boolean
  showLabelOnMobile?: boolean
  full?: boolean
}

type Emits = {
  (e: 'change', value: string): void
}

const { placement = 'bottom-end', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()
const providers = ['Google Drive', 'Dropbox', 'OneDrive']

const data = computed(() => [
  ...providers.map(label => ({
    label,
    icon: h(UIServiceIcon, { path: label, isNotLink: true }),
    action: (hide: () => void) => {
      hide()
      emit('change', label)
    },
  })),
  {
    label: 'Other Link',
    icon: LinkIcon,
    action: (hide: () => void) => {
      hide()
      emit('change', 'Other Link')
    },
  },
])

const mainClasses = computed(() => `${props.full ? 'w-full ' : ''}flex`)
</script>
