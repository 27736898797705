<template>
  <div class="ui-subscription-plan">
    <div class="ui-subscription-plan__name">{{ name }}</div>
    <div class="ui-subscription-plan__price">
      <p class="ui-subscription-plan__price-value">
        {{ priceValue }}
        <span class="ui-subscription-plan__price-unit">
          /{{ plan.period_unit }}
        </span>
      </p>
      <UIButton v-bind="{ disabled, loading }" full @click="handleClick">
        {{ label }}
      </UIButton>
    </div>
    <div class="ui-subscription-plan__features">
      <div class="ui-subscription-plan__features-caption">What’s included</div>
      <SubscriptionPlanFeatures v-bind="{ features }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, ref } from 'vue'

import { UserPlan } from '@types'

import { numberFormat } from '@/helpers/numbers'

import SubscriptionPlanFeatures from './SubscriptionPlanFeatures.vue'
import { UIButton } from '@ui/buttons'

type Props = {
  plan: UserPlan
  buttonText?: string
  disabled?: boolean
  isCurrentPlan?: boolean
}

type Emits = {
  click: [plan: UserPlan, callback: () => void]
}

const { buttonText = 'Change plan', ...props } = defineProps<Props>()

const emit = defineEmits<Emits>()

defineOptions({
  name: 'UISubscriptionPlan',
})

const currency = inject<ComputedRef<string>>('currency')

const loading = ref(false)

const name = computed(() => props.plan.name)
const disabled = computed(() => props.isCurrentPlan || props.disabled)
const label = computed(() =>
  props.isCurrentPlan ? 'Your current plan' : buttonText,
)

const priceValue = computed(() =>
  numberFormat(props.plan.price, {
    currency: currency?.value,
  }),
)

const features = computed(() => props.plan.plan_schema.features || [])

const handleClick = () => {
  loading.value = true
  emit('click', props.plan, () => {
    loading.value = false
  })
}
</script>

<style>
.ui-subscription-plan {
  @apply w-full max-w-80;
  @apply flex flex-col items-center;
  @apply gap-6 p-6;
  @apply rounded-md;
  @apply ring-1 ring-inset ring-gray-200 dark:ring-gray-600;
  @apply text-center;

  &__name {
    @apply text-xl font-bold leading-6;
    @apply uppercase;
    @apply text-gray-900 dark:text-gray-100;
  }

  &__price {
    @apply w-full;
    @apply flex flex-col items-center;
    @apply gap-6;
  }

  &__price-value {
    @apply text-3xl font-bold tracking-tight;
    @apply text-gray-900 dark:text-gray-200;
  }

  &__price-unit {
    @apply text-light-text;
    @apply text-base font-normal;
  }

  &__features {
    @apply w-full sm:w-4/5;
    @apply flex flex-col;
    @apply text-left;
  }

  &__features-caption {
    @apply mb-2;
    @apply font-bold;
  }
}
</style>
