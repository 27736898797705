<template>
  <div class="timeline-periods">
    <UIInputSelect
      ref="inputRef"
      v-model="value"
      :data
      :disabled
      :size
      @blur="handleBlur"
      @select="handleSelect"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { InputSize, RepositoryTimeline } from '@types'

import { PERIODS_LIMIT } from './utils/const'

import { UIInputSelect } from '@ui/fields'

type Props = {
  disabled?: boolean
  size?: InputSize
}

type Emits = {
  blur: []
  select: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<RepositoryTimeline['periods']>()

defineOptions({
  name: 'TimelinePeriods',
})

const inputRef = useTemplateRef('inputRef')

defineExpose({
  activate: () => {
    inputRef.value?.focus()?.open()
  },
})

const value = computed({
  get() {
    return modelValue.value?.toString() ?? ''
  },
  set(value) {
    modelValue.value = Number(value)
  },
})

const data = computed(() =>
  Array.from(Array(PERIODS_LIMIT), (_, index) => {
    const value = (index + 1).toString()
    return {
      value,
      label: value,
    }
  }),
)

const handleBlur = () => {
  emit('blur')
}

const handleSelect = () => {
  emit('select')
}
</script>

<style>
.timeline-periods {
  @apply w-16;

  .ui-timeline--short & {
    @apply w-[3.25rem];
  }
}
</style>
