<template>
  <div class="ui-panel">
    <div class="ui-panel__search" :class="searchClasses">
      <UIPanelSearch
        v-bind="{ disabled, placeholder, size }"
        v-model="modelValue"
        v-model:storage="modelStorage"
      />
    </div>
    <slot
      v-bind="{
        size,
        ButtonCancel,
        ButtonDelete,
        ButtonDuplicate,
        ButtonExport,
        ButtonExportAdvanced,
        ButtonGroup,
        ButtonImport,
        ButtonNew,
        ButtonSaveChanges,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'

import { ButtonSize } from '../Button/utils/types'

import { UIPanelSearch } from '@ui/core'
import ButtonCancel from './components/Cancel.vue'
import ButtonDelete from './components/Delete.vue'
import ButtonDuplicate from './components/Duplicate.vue'
import ButtonExport from './components/Export.vue'
import ButtonExportAdvanced from './components/ExportAdvanced.vue'
import ButtonGroup from './components/Group.vue'
import ButtonImport from './components/Import.vue'
import ButtonNew from './components/ButtonNew.vue'
import ButtonSaveChanges from './components/SaveChanges.vue'

type Props = {
  placeholder?: string
  size?: ButtonSize
  disabled?: boolean
  fillOnMobile?: boolean
}

const { size = 'sm', fillOnMobile } = defineProps<Props>()

const modelValue = defineModel<string>()
const modelStorage = defineModel<string>('storage')

defineOptions({
  name: 'UIPanel',
})

provide('size', size)

const searchClasses = computed(() => ({
  'ui-panel__search--fill': fillOnMobile,
}))
</script>

<style scoped>
.ui-panel {
  @apply flex items-center flex-wrap;
  @apply gap-1.5 md:gap-2;
  @apply mb-3 pt-1 md:pt-0;

  &__search {
    @apply md:min-w-[19rem];
    @apply flex-auto;

    &--fill {
      @apply w-full sm:w-auto;
    }
  }
}
</style>
