<template>
  <nav class="ui-stepper" aria-label="Progress">
    <p class="ui-stepper__info">
      Step
      {{ currentStep }} of
      {{ count }}
    </p>
    <ol class="ui-stepper__points">
      <li v-for="index in count" :key="index">
        <span
          v-if="index === currentStep"
          class="ui-stepper__point ui-stepper__point--current"
          aria-current="step"
        />
        <span
          v-else
          class="ui-stepper__point"
          :class="{
            'ui-stepper__point--prev': index < currentStep,
            'ui-stepper__point--next': index > currentStep,
          }"
          @click="step = index - 1"
        />
      </li>
    </ol>
  </nav>
  <div class="ui-stepper__buttons">
    <UIButton
      :label="previousLabel"
      :disabled
      type="button"
      variant="gray"
      fill="outlined"
      @click="emit('prev')"
    />
    <UIButton
      :label="nextLabel"
      :disabled
      :loading
      type="button"
      @click="emit('next')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import UIButton from '../Button/Button.vue'

type Props = {
  count: number

  nextLabel: string
  previousLabel: string

  disabled?: boolean
  loading?: boolean
}

type Emits = {
  (e: 'prev'): void
  (e: 'next'): void
}

const step = defineModel<number>({ default: 0 })

defineProps<Props>()

const emit = defineEmits<Emits>()

const currentStep = computed(() => step.value + 1)
</script>

<style>
.ui-stepper {
  @apply grid grid-cols-2 gap-3;

  &__info {
    @apply text-sm font-medium;
  }

  &__points {
    @apply flex items-center justify-end;
    @apply ml-8 gap-x-5;
  }

  &__point {
    @apply w-2.5 h-2.5;
    @apply block;
    @apply rounded-full;

    &--prev,
    &--current {
      @apply bg-indigo-600 dark:bg-indigo-300;
      @apply hover:bg-indigo-900 dark:hover:bg-indigo-400;
    }

    &--current {
      @apply outline outline-4 outline-indigo-200 dark:outline-indigo-400;
    }

    &--next {
      @apply bg-gray-200 dark:bg-gray-400 hover:bg-gray-400 dark:hover:bg-gray-600;
    }

    &--prev,
    &--next {
      @apply cursor-pointer;
    }
  }

  &__buttons {
    @apply grid grid-cols-2 gap-3;
  }
}
</style>
