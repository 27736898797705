<template>
  <div v-if="password" class="password-validation">
    <div class="password-validation__title">Password must use:</div>
    <PasswordRule text="8-24 characters" :valid="rules.length" />
    <PasswordRule text="An uppercase letter" :valid="rules.uppercase" />
    <PasswordRule text="A lowercase letter" :valid="rules.lowercase" />
    <PasswordRule text="A number" :valid="rules.number" />
    <PasswordRule
      text="One of these special characters + = # % ? * $ ! - _"
      :valid="rules.special"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import PasswordRule from './PasswordRule.vue'

type Props = {
  password: string
}

const props = defineProps<Props>()
const modelValue = defineModel<boolean>({ required: true })

const rules = computed(() => {
  return {
    length: new RegExp('^.{8,24}$').test(props.password),
    uppercase: new RegExp('.*[A-Z]').test(props.password),
    lowercase: new RegExp('.*[a-z]').test(props.password),
    number: new RegExp('.*[0-9]').test(props.password),
    special: new RegExp('.*[+=#%?*$!_-]').test(props.password),
  }
})

watch(
  () => props.password,
  () => {
    modelValue.value = Object.values(rules.value).every(rule => rule)
  },
)
</script>

<script lang="ts">
export default {
  name: 'UIPasswordValidation',
}
</script>

<style scoped>
.password-validation {
  @apply space-y-1;
}
.password-validation__title {
  @apply text-gray-500 text-xs;
}
</style>
