<template>
  <div class="ui-draggable-wrapper" :class="mainClasses">
    <div v-if="label" class="ui-draggable-wrapper__label">{{ label }}</div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  label?: string
}

const props = defineProps<Props>()

defineOptions({
  name: 'UIDraggableWrapper',
})

const mainClasses = computed(() => ({
  'ui-draggable-wrapper--label': !!props.label,
}))
</script>

<style>
.ui-draggable-wrapper {
  @apply px-4 py-3 inline-block space-y-2;
  @apply border rounded;
  @apply border-gray-200 dark:border-gray-600;

  &--label {
    @apply pt-2;
  }

  &__label {
    @apply text-xs text-gray-500 dark:text-gray-400;
  }
}
</style>
