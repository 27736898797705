<template>
  <div class="ui-gallery">
    <div class="ui-gallery__header">
      <div class="ui-gallery__title">
        {{ name }}
      </div>
      <div class="ui-gallery__controls" :class="controlsClasses">
        <ChevronDoubleLeftIcon
          class="ui-gallery__chevron"
          :class="leftClasses"
          @click="() => scroll('left')"
        />
        <ArrowLeftIcon
          class="ui-gallery__arrow"
          :class="leftClasses"
          @click="() => scroll('stepLeft')"
        />
        <ArrowRightIcon
          class="ui-gallery__arrow"
          :class="rightClasses"
          @click="() => scroll('stepRight')"
        />
        <ChevronDoubleRightIcon
          class="ui-gallery__chevron"
          :class="rightClasses"
          @click="() => scroll('right')"
        />
      </div>
    </div>
    <div
      ref="body"
      class="ui-gallery__body no-scrollbar"
      @scroll.passive="handleScroll"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, computed } from 'vue'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/20/solid'

type Props = {
  name?: string
}

defineProps<Props>()

// TODO: move offset to props
const offset = 200
const body = ref<HTMLDivElement>()
const canScroll = reactive({
  left: false,
  right: true,
  hideScrolls: false,
})

const controlsClasses = computed(() => ({
  'ui-gallery__controls--hidden': canScroll.hideScrolls,
}))

const leftClasses = computed(() => ({
  'ui-gallery__control': canScroll.left,
  'ui-gallery__control--disabled': !canScroll.left,
}))

const rightClasses = computed(() => ({
  'ui-gallery__control': canScroll.right,
  'ui-gallery__control--disabled': !canScroll.right,
}))

const handleScroll = (e: Event) => {
  const val = e.target as HTMLDivElement
  canScroll.right = val.scrollLeft + val.offsetWidth < val.scrollWidth
  canScroll.left = val.scrollLeft > offset / 2
  canScroll.hideScrolls = body.value
    ? body.value?.scrollWidth <= body.value?.offsetWidth
    : true
}

onMounted(() => {
  canScroll.hideScrolls = body.value
    ? body.value?.scrollWidth <= body.value?.offsetWidth
    : true
})

const scroll = (to: 'left' | 'right' | 'stepRight' | 'stepLeft') => {
  if (body.value) {
    let left = 0
    switch (to) {
      case 'left':
        left = 0
        break
      case 'right':
        left = body.value?.scrollWidth
        break
      case 'stepLeft':
        left = body.value.scrollLeft - offset
        break
      case 'stepRight':
        left = body.value.scrollLeft + offset
        break
    }
    body.value.scrollTo({
      left,
      behavior: 'smooth',
    })
  }
}
</script>

<script lang="ts">
export default {
  name: 'UIGallery',
}
</script>

<style lang="postcss">
.ui-gallery {
  @apply flex flex-col;
  @apply gap-2;

  &__header {
    @apply flex justify-between;
  }

  &__title {
    @apply text-lg;
    @apply leading-none;
    @apply text-gray-950 dark:text-gray-300;
  }

  &__controls {
    @apply flex items-center;
    @apply gap-x-6;

    &--hidden {
      @apply hidden;
    }
  }

  &__control {
    @apply text-gray-500 hover:text-indigo-700;
    @apply dark:text-gray-400 dark:hover:text-indigo-300;
    @apply cursor-pointer;

    &--disabled {
      @apply text-gray-300 dark:text-gray-500;
    }
  }

  &__chevron {
    @apply h-4;
  }

  &__arrow {
    @apply h-5;
    @apply block;
  }

  &__body {
    @apply flex;
    @apply -m-1 px-1 py-1;
    @apply gap-2;
    @apply overflow-x-scroll;

    & > * {
      scroll-snap-align: start;
    }
  }
}
</style>
