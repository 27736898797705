<template>
  <UIButton
    v-bind="{ size }"
    :leading="XMarkIcon"
    label="Cancel"
    variant="gray"
    fill="outlined"
    hide-label-to-sm
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSize } from '@types'

import { XMarkIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui/buttons'

const size = inject<ButtonSize>('size')
</script>
