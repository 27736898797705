<template>
  <UIDropdownActions :data placement="bottom-start">
    <template #default="{ toggle }">
      <UIButton
        v-bind="{ size }"
        :leading="ArrowUpTrayIcon"
        label="Export"
        variant="gray"
        fill="light"
        hide-label-to-sm
        @click="toggle"
      />
    </template>
  </UIDropdownActions>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { ButtonSize } from '@types'

import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline'

import { UIButton } from '@ui/buttons'
import { UIDropdownActions } from '@ui/dropdowns'

type Props = {
  exportAllLabel?: string
}
const { exportAllLabel = 'Export All' } = defineProps<Props>()

type Emits = {
  'export:all': []
  'export:selected': []
}
const emit = defineEmits<Emits>()

const size = inject<ButtonSize>('size')

const data = computed(() => [
  {
    label: exportAllLabel,
    action: () => {
      emit('export:all')
    },
  },
  {
    label: 'Export Selected',
    action: () => {
      emit('export:selected')
    },
  },
])
</script>
