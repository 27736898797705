<template>
  <li class="ui-steps-item" :class="mainClasses" @click="handleClick">
    <div
      v-if="index"
      class="ui-steps-item__connector"
      :class="connectorClasses"
      aria-hidden="true"
    />
    <div class="ui-steps-item__area group">
      <span class="ui-steps-item__circle" :class="circleClasses">
        <component
          :is="iconComponent"
          v-if="iconComponent"
          class="ui-steps-item__icon"
          aria-hidden="true"
        />
        <span v-else class="ui-steps-item__point" />
      </span>
      <span class="ui-steps-item__info">
        <span class="ui-steps-item__name">
          {{ step.name }}
        </span>
        <span v-if="step.description" class="ui-steps-item__description">
          {{ step.description }}
        </span>
      </span>
    </div>
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { StepsNavigationStatus } from './utils/enums'
import { StepsNavigation } from './utils/types'

import { CheckIcon, MinusIcon } from '@heroicons/vue/20/solid'

type Props = {
  step: StepsNavigation
  index: number
  currentStep: number
  isLastStep: boolean
  lastUncompletedIndex: number
  visited: number[]
}

type Emits = {
  click: [index: number]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const isVisitedItem = computed(() => props.visited.includes(props.index))
const isCurrentItem = computed(
  () => props.index === props.currentStep && isVisitedItem.value,
)
const isCompletedItem = computed(
  () =>
    isVisitedItem.value &&
    props.step.status &&
    [StepsNavigationStatus.COMPLETED, StepsNavigationStatus.SKIPPED].includes(
      props.step.status,
    ),
)

const isDisabledItem = computed(() => {
  return props.index > props.lastUncompletedIndex
})

const isCurrentLastItem = computed(
  () => props.isLastStep && isCurrentItem.value,
)

const iconComponent = computed(() => {
  if (isCurrentLastItem.value) return CheckIcon
  if (!isVisitedItem.value || isDisabledItem.value) return undefined
  switch (props.step.status) {
    case StepsNavigationStatus.COMPLETED:
      return CheckIcon
    case StepsNavigationStatus.SKIPPED:
      return MinusIcon
    default:
      return undefined
  }
})

const mainClasses = computed(() => ({
  'ui-steps-item--current': isCurrentItem.value,
  'ui-steps-item--disabled': isDisabledItem.value,
}))

const connectorClasses = computed(() => ({
  'ui-steps-item__connector--completed':
    isCompletedItem.value && !isDisabledItem.value,
}))

const circleClasses = computed(() => ({
  'ui-steps-item__circle--completed':
    isCurrentLastItem.value || (isCompletedItem.value && !isDisabledItem.value),
}))

const handleClick = () => {
  if (isDisabledItem.value) return
  emit('click', props.index)
}
</script>

<style>
.ui-steps-item {
  @apply relative;
  @apply pt-2 md:pt-10 first:!pt-0;

  &:not(&--disabled) {
    @apply cursor-pointer;
  }

  .ui-steps--shrink & {
    @apply md:pt-7;
  }

  &__connector {
    @apply w-0.5;
    @apply absolute left-2.5 md:left-4 inset-y-0;
    @apply -ml-px -mt-1;
    @apply bg-common-border;

    .ui-steps-item--current &,
    &--completed {
      @apply bg-primary-selected;
    }
  }

  &__area {
    @apply relative;
    @apply flex items-center;
  }

  &__circle {
    @apply w-5 h-5 md:w-8 md:h-8;
    @apply flex items-center justify-center;
    @apply relative;
    @apply bg-body-gray;
    @apply border-2 border-common-border;
    @apply rounded-full;
    @apply z-10;

    &--completed {
      @apply bg-primary-selected;
      @apply !border-none;
    }

    .ui-steps-item--current & {
      @apply !border-primary-selected;
    }
  }

  &__icon {
    @apply w-3.5 h-3.5 md:w-5 md:h-5;
    @apply rounded-none;
    @apply !bg-transparent;
    @apply text-white;
  }

  &__point {
    @apply w-2 h-2 md:w-2.5 md:h-2.5;
    @apply rounded-full;
    @apply bg-transparent;

    .ui-steps-item--current & {
      @apply !bg-primary-selected;
    }

    .ui-steps-item:not(.ui-steps-item--disabled):hover & {
      @apply bg-common-border;
    }
  }

  &__info {
    @apply min-w-0;
    @apply flex flex-col;
    @apply ml-4;

    .ui-steps-item--disabled & {
      @apply opacity-50;
    }
  }

  &__name {
    @apply text-sm font-medium;
    @apply text-common-text;

    .ui-steps-item--current & {
      @apply text-primary-active dark:text-indigo-400;
    }
  }

  &__description {
    @apply text-light-text;

    .ui-steps-item--current & {
      @apply text-primary-light dark:text-indigo-500;
    }
  }
}
</style>
