<template>
  <div class="ui-subscription-wrapper">
    <UIYesNoSwitch
      v-model="isYearUnit"
      label-yes="Year"
      label-no="Month"
      variant="secondary"
    />
  </div>
  <div class="ui-subscription-wrapper__plans">
    <slot :plans />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { UserPlan, UserPlanUnit } from '@types'

import { UIYesNoSwitch } from '@ui/controllers'

type Props = {
  plansList: UserPlan[]
}

const props = defineProps<Props>()

const isYearUnit = ref(true)

const plans = computed(() =>
  props.plansList.filter(plan =>
    isYearUnit.value
      ? plan.period_unit === UserPlanUnit.YEAR
      : plan.period_unit === UserPlanUnit.MONTH,
  ),
)
</script>

<style>
.ui-subscription-wrapper {
  @apply flex justify-center;
  @apply mt-4;

  &__plans {
    @apply flex flex-row flex-wrap justify-center;
    @apply gap-4 p-4;
  }
}
</style>
