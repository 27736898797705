<template>
  <div class="rule">
    <div v-if="valid" class="rule__icon rule__icon--valid">
      <CheckIcon aria-hidden="true" />
    </div>
    <div v-else class="rule__icon">
      <XMarkIcon aria-hidden="true" />
    </div>
    <span>{{ text }}</span>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid'

type Props = {
  text: string
  valid?: boolean
}

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'PasswordRule',
}
</script>

<style scoped>
.rule {
  @apply flex items-center;
  @apply space-x-2;
  @apply text-gray-500 text-xs;
}
.rule__icon {
  @apply rounded-full;
  @apply text-white;
  @apply bg-red-500 border border-red-500;
}
.rule__icon--valid {
  @apply bg-green-600 border-green-600;
}
.rule__icon svg {
  @apply w-3 h-3;
}
</style>
