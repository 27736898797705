<template>
  <nav aria-label="Progress" class="ui-steps" :class="mainClasses">
    <ol role="list" class="ui-steps__list">
      <StepsNavigationItem
        v-for="(step, index) in steps"
        :key="step.name"
        v-bind="{ index, step, currentStep, lastUncompletedIndex, visited }"
        :is-last-step="index === steps.length - 1"
        @click="handleClick"
      />
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { StepsNavigation } from './utils/types'

import StepsNavigationItem from './StepsNavigationItem.vue'

type Props = {
  steps: StepsNavigation[]
  disabled?: boolean
  shrink?: boolean
}

const props = defineProps<Props>()

const currentStep = defineModel<number>({ default: 0 })

const visited = ref<number[]>([])

const mainClasses = computed(() => ({
  'ui-steps--shrink': props.shrink,
}))

const lastUncompletedIndex = computed(() => {
  return props.steps.findIndex(step => step.status === undefined)
})

const handleClick = (index: number) => {
  if (props.disabled) return
  currentStep.value = index
}

watch(
  currentStep,
  value => {
    if (visited.value.find(item => item === value)) return
    visited.value.push(value)
  },
  { immediate: true },
)
</script>

<style>
.ui-steps {
  &__list {
    @apply overflow-hidden;
  }
}
</style>
