<template>
  <div class="timeline-freq">
    <UIInputSelect
      ref="inputRef"
      v-model="value"
      :data
      :disabled
      :size
      class="timeline-freq__field"
      @blur="handleBlur"
      @select="handleSelect"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { InputSize, RepositoryTimeline } from '@types'

import { FREQUENCY_VALUES } from './utils/const'

import { UIInputSelect } from '@ui/fields'

type Props = {
  disabled?: boolean
  size?: InputSize
}

type Emits = {
  blur: []
  select: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<RepositoryTimeline['freq']>()

defineOptions({
  name: 'TimelineFreq',
})

defineExpose({
  activate: () => {
    inputRef.value?.focus()?.open()
  },
})

const inputRef = useTemplateRef('inputRef')

const value = computed({
  get() {
    return modelValue.value?.toString() ?? ''
  },
  set(value) {
    modelValue.value = value as RepositoryTimeline['freq']
  },
})

const data = computed(() => FREQUENCY_VALUES)

const handleBlur = () => {
  emit('blur')
}

const handleSelect = () => {
  emit('select')
}
</script>

<style>
.timeline-freq {
  @apply flex-auto w-32;

  .ui-timeline--short & {
    @apply w-[6.75rem];
  }

  &__field {
    @apply text-gray-500;
  }
}
</style>
