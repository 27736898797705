<template>
  <div class="ui-trash-can">
    <UIButtonIcon
      v-tooltip="'Trash'"
      v-bind="{ size }"
      variant="gray"
      fill="light"
      :leading="TrashIcon"
      class="ui-trash-can__button"
    />
    <div v-if="count" class="ui-trash-can__count">
      {{ count }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useResponsive } from '@/plugins/responsiveUI'

import { TrashIcon } from '@heroicons/vue/24/outline'

import { UIButtonIcon } from '@ui/buttons'

type Props = {
  count?: number
}

defineProps<Props>()

defineOptions({
  name: 'UITrashCan',
})

const { isMobile, isTablet } = useResponsive()

const isMobileView = computed(() => isMobile.value || isTablet.value)

const size = computed(() => (isMobileView.value ? undefined : 'xs'))
</script>

<style scoped>
.ui-trash-can {
  @apply relative;
  @apply flex items-center;

  &__count {
    @apply absolute;
    @apply top-0.5 right-0.5;
    @apply text-xs rounded;
  }
}
</style>
