<template>
  <UICard ref="cardRef" class="ui-account-info" @click="handleClick">
    <div
      v-if="connector"
      class="ui-account-info__connector"
      :class="connectorClasses"
    >
      <div
        class="ui-account-info__connector-name"
        :class="connectorNameClasses"
      >
        <div class="ui-account-info__caption">Connector Name:</div>
        {{ displayConnectorName }}
      </div>
      <div class="ui-account-info__connector-method">
        <div class="ui-account-info__caption">Access Method:</div>
        {{ displayConnectorMethod }}
      </div>
      <div class="ui-account-info__connector-source">
        <div class="ui-account-info__caption">Source:</div>
        {{ displayConnectorSource }}
      </div>
      <div class="ui-account-info__connector-buttons" :class="buttonsClasses">
        <slot name="connector" />
      </div>
    </div>
    <div
      v-if="displayConnectorStatus"
      class="ui-account-info__connector-status"
    >
      <div class="ui-account-info__connector-status-wrapper">
        <span class="ui-account-info__caption">Status:</span>
        <span
          class="ui-account-info__connector-status-value"
          :class="statusClasses"
        >
          {{ displayConnectorStatus }}
        </span>
      </div>
      <div
        v-if="displayConnectorStatusMessage"
        class="ui-account-info__connector-status-message"
      >
        {{ displayConnectorStatusMessage }}
      </div>
    </div>
    <div class="ui-account-info__area" :class="areaClasses">
      <div class="ui-account-info__name" :class="nameClasses">
        <div class="ui-account-info__caption">{{ prefix }}Name:</div>
        {{ displayAccountName }}
      </div>
      <div class="ui-account-info__number" :class="numberClasses">
        <div class="ui-account-info__caption">{{ prefix }}Number:</div>
        {{ displayAccountNumber }}
      </div>
      <div class="ui-account-info__as-of">
        <div class="ui-account-info__caption">As Of:</div>
        {{ displayAsOf }}
      </div>
      <div class="ui-account-info__date">
        <div class="ui-account-info__caption">{{ updatedCaption }}:</div>
        {{ displayLastUpdated }}
      </div>
      <div class="ui-account-info__balance" :class="balanceClasses">
        <div class="ui-account-info__caption">Balance:</div>
        <span class="blurable-number" :class="balanceValueClasses">{{
          accountBalance
        }}</span>
      </div>
    </div>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
    <div v-if="account.deleted" class="ui-account-info__deleted">Deleted</div>
  </UICard>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, useTemplateRef } from 'vue'

import { LinkedDataAccount, LinkedDataConnector } from '@types'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'
import { CONNECTOR_SYNC_STATUS_TEXT } from '@/views/LinkedData/utils/const'
import { BREAKPOINTS } from './utils/const'

import { numberFormat } from '@/helpers/numbers'
import { stringToLocalDateString } from '@/helpers/dates'
import { getCurrencySymbol } from '@/helpers/common'

import { useLinkedDataConnector } from '@/hooks/linkedDataConnector'

import { UICard } from '@ui/core'

type Props = {
  account: LinkedDataAccount
  connector?: LinkedDataConnector
}

type Emits = {
  click: [data: LinkedDataAccount]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { syncingConnectors } = useLinkedDataConnector()

const cardRef = useTemplateRef<typeof UICard>('cardRef')
const observer = ref<ResizeObserver>()
const size = ref<'sm' | 'md'>()

const isSyncing = computed(
  () =>
    !!props.connector?.source &&
    syncingConnectors.value.includes(props.connector.source.toLowerCase()),
)

const displayConnectorName = computed(() => props.connector?.name)
const displayConnectorMethod = computed(() => props.connector?.access_method)
const displayConnectorSource = computed(() => props.connector?.source)
const displayConnectorStatus = computed(() =>
  isSyncing.value
    ? 'Syncing'
    : props.connector?.status &&
      CONNECTOR_SYNC_STATUS_TEXT[props.connector?.status],
)
const displayConnectorStatusMessage = computed(
  () => props.connector?.status_message,
)
const statusValue = computed(() => displayConnectorStatus.value?.toLowerCase())

const displayAccountName = computed(() => props.account?.account_name)
const displayAccountNumber = computed(() => props.account?.account_number)
const displayAsOf = computed(() =>
  stringToLocalDateString(props.account?.account_last_updated, {
    relative: true,
  }),
)
const updatedCaption = computed(() =>
  props.account.deleted ? 'Deleted on' : 'Last Updated',
)
const displayLastUpdated = computed(() => {
  const updatedDate = props.account.deleted
    ? props.account.updated_at
    : props.account?.account_last_updated
  return stringToLocalDateString(updatedDate, {
    relative: true,
  })
})

const currency = computed(
  () => props.account && getCurrencySymbol(props.account.balance_currency),
)
const accountBalance = computed(
  () =>
    numberFormat(props.account?.balance_amount, {
      currency: currency.value,
    }) || EMPTY_VALUE_PLACEHOLDER,
)

const prefix = computed(() => (props.connector ? 'Account ' : undefined))

const connectorClasses = computed(() => ({
  [`ui-account-info__connector--${size.value}`]: size.value,
}))

const connectorNameClasses = computed(() => ({
  [`ui-account-info__connector-name--${size.value}`]: size.value,
}))

const buttonsClasses = computed(() => ({
  [`ui-account-info__connector-buttons--${size.value}`]: size.value,
}))

const areaClasses = computed(() => ({
  [`ui-account-info__area--${size.value}`]: size.value,
}))

const nameClasses = computed(() => ({
  [`ui-account-info__name--${size.value}`]: size.value,
}))

const numberClasses = computed(() => ({
  [`ui-account-info__number--${size.value}`]: size.value,
}))

const balanceClasses = computed(() => ({
  [`ui-account-info__balance--${size.value}`]: size.value,
}))

const balanceValueClasses = computed(() => ({
  'ui-account-info__balance--negative': props.account?.balance_amount < 0,
}))

const statusClasses = computed(() => ({
  [`ui-account-info__connector-status-value--${statusValue.value}`]:
    statusValue.value,
}))

const handleClick = () => {
  emit('click', props.account)
}

onMounted(() => {
  if (!cardRef.value) return
  observer.value = new ResizeObserver(entries => {
    const container = entries[0]
    if (!container) return
    const rect = container.target.getBoundingClientRect()
    const width = rect.width
    size.value = undefined
    if (width > BREAKPOINTS.SM && width <= BREAKPOINTS.MD) {
      size.value = 'md'
    } else if (width <= BREAKPOINTS.SM) {
      size.value = 'sm'
    }
  })
  observer.value.observe(cardRef.value.getEl())
})

onUnmounted(() => {
  observer.value?.disconnect()
})
</script>

<script lang="ts">
export default {
  name: 'UIAccountInfo',
}
</script>

<style lang="postcss">
.ui-account-info {
  @apply relative;
  @apply shrink-0;
  @apply !transition-none;

  &__connector,
  &__area {
    @apply grid;
    @apply grid-cols-5;
    @apply gap-4;
    @apply text-sm;
    @apply text-gray-600 dark:text-gray-400;

    &--md {
      @apply gap-2;
    }

    &--sm {
      @apply grid-cols-1;
    }
  }

  &__area {
    &--md {
      @apply grid-cols-3;
    }
  }

  &__connector,
  &__connector-status {
    @apply pb-4 mb-4;
    @apply border-b border-gray-200 dark:border-gray-600;
  }

  &__connector {
    &--md {
      @apply grid-cols-[2fr_1.5fr_1fr_1fr];
    }
  }

  &__connector-status-wrapper {
    @apply flex items-start gap-1;
    @apply leading-none;
  }

  &__connector-status-value {
    @apply text-gray-600 dark:text-gray-400;

    &--error {
      @apply text-red-800 dark:text-red-300;
    }
    &--warning {
      @apply text-yellow-800 dark:text-yellow-300;
    }
  }

  &__connector-status-message {
    @apply mt-1.5;
    @apply text-xs leading-none;
    @apply text-gray-400;
  }

  &__caption,
  &__connector-status-value {
    @apply text-xs;
    @apply font-medium;
    @apply uppercase;
  }
  &__caption {
    @apply text-gray-400 dark:text-gray-300;
  }
  &__connector-name {
    &--sm {
      @apply pr-24;
    }
  }
  &__name {
    @apply col-span-1;

    &--md {
      @apply col-span-2;
    }
  }
  &__number {
    &--md {
      @apply text-right;
    }

    &--sm {
      @apply text-left;
    }
  }
  &__as-of {
    @apply whitespace-nowrap;
  }
  &__connector-buttons,
  &__balance {
    @apply text-right;

    &--sm {
      @apply text-left;
    }
  }
  &__connector-buttons {
    @apply col-span-2;

    &--md,
    &--sm {
      @apply col-span-1;
    }
    &--sm {
      @apply absolute top-4 right-4;
    }
  }
  &__balance {
    @apply text-slate-950 dark:text-green-400;

    &--negative {
      @apply text-red-600 dark:text-red-400;
    }
  }
  &__date {
    @apply whitespace-nowrap;
  }
  &__deleted {
    @apply absolute inset-0;
    @apply flex items-center justify-center;
    @apply bg-white dark:bg-gray-800 !bg-opacity-50;
    @apply text-red-600 dark:text-red-400;
    @apply text-sm uppercase;
    @apply rounded-md;
    @apply z-10;
  }
}
</style>
