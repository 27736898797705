<template>
  <UIInputTextClearable v-model="modelStorage">
    <template #leading>
      <MagnifyingGlassIcon aria-hidden="true" />
    </template>
  </UIInputTextClearable>
</template>

<script setup lang="ts">
import { watch } from 'vue'

import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'

import { UIInputTextClearable } from '@ui/fields'

const modelValue = defineModel<string>()
const modelStorage = defineModel<string>('storage', { default: '' })

watch(
  modelStorage,
  value => {
    modelValue.value = value?.trim() || undefined
  },
  { immediate: true },
)
</script>
