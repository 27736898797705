<template>
  <div class="ui-card-ticker" :class="mainClasses" @click="handleClick">
    <div class="ui-card-ticker__header" :class="headerClasses">
      <div class="ui-card-ticker__name" :class="nameClasses">
        <div class="ui-card-ticker__ticker" :class="tickerClasses">
          {{ displayTicker }}
        </div>
        <div v-if="displayName" class="ui-card-ticker__title">
          {{ displayName }}
        </div>
        <div v-if="displayDescription" class="ui-card-ticker__description">
          {{ displayDescription }}
        </div>
      </div>
    </div>
    <div class="ui-card-ticker__footer">
      <div class="ui-card-ticker__label blurable-number" :class="labelClasses">
        {{ displayAmount }}
      </div>
      <div
        v-if="percent"
        class="ui-card-ticker__percent"
        :class="percentClasses"
      >
        {{ displayPercent }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ComputedRef, computed, inject } from 'vue'

import { CardTickerItem } from './utils/types'

import { numberFormat } from '@/helpers/numbers'

type Props = {
  item: CardTickerItem
  isCurrent?: boolean
}

type Emits = {
  'click:item': [data: CardTickerItem]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const currency = inject<ComputedRef<string>>('currency')

const displayTicker = computed(() => props.item.ticker)

const displayName = computed(() => props.item.name)

const displayDescription = computed(() => props.item.description)

const mainClasses = computed(() => ({
  'ui-card-ticker--active': props.isCurrent,
}))

const headerClasses = computed(() => ({
  'ui-card-ticker__header--inline': !displayDescription.value,
}))

const nameClasses = computed(() => ({
  'ui-card-ticker__name--active': props.isCurrent,
  'ui-card-ticker__name--short': !displayName.value,
}))

const tickerClasses = computed(() => ({
  'ui-card-ticker__ticker--precreated': !displayName.value,
}))

const amount = computed(() => props.item.amount)

const percent = computed(() => undefined)

const labelClasses = computed(() => {
  return {
    'ui-card-ticker__label--increase': !amount.value || amount.value >= 0,
    'ui-card-ticker__label--decrease': amount.value && amount.value < 0,
  }
})

const percentClasses = computed(() => ({
  'ui-card-ticker__percent--increase': percent.value && percent.value > 0,
  'ui-card-ticker__percent--decrease': percent.value && percent.value < 0,
}))

const displayAmount = computed(() => {
  if (!amount.value) return
  let result = `${amount.value > 0 ? '↑' : '↓'}`
  result += currency?.value
  result += numberFormat(Math.abs(amount.value))
  return result
})

const displayPercent = computed(() => {
  if (!percent.value) return
  let result = `${percent.value > 0 ? '↑' : '↓'}`
  result += `${numberFormat(Math.abs(percent.value))}%`
  return result
})

const handleClick = () => {
  emit('click:item', props.item)
}
</script>

<script lang="ts">
export default {
  name: 'UICardTicker',
}
</script>

<style scoped lang="postcss">
.ui-card-ticker {
  @apply flex flex-col justify-between sm:items-center;
  @apply shrink-0;
  @apply w-48 min-w-max;
  @apply p-4;
  @apply border border-transparent;
  @apply bg-white dark:bg-gray-850;
  @apply shadow;
  @apply rounded-lg;
  @apply overflow-hidden;
  @apply cursor-pointer;

  &--active {
    @apply border-indigo-400 dark:border-indigo-600;
  }

  &__header {
    @apply w-full;
    @apply flex flex-col sm:flex-row justify-between;

    &--inline {
      @apply sm:items-center;
    }
  }

  &__name {
    @apply space-y-1.5;
    @apply text-sm;
    @apply sm:leading-tight;
    @apply text-gray-900 dark:text-gray-100;

    &--active {
      @apply text-indigo-600 dark:text-indigo-400;
    }

    &--short {
      @apply space-y-2.5;
    }
  }

  &__ticker {
    @apply inline-block;
    @apply py-1 px-2;
    @apply rounded;
    @apply text-xs;
    @apply bg-indigo-600 dark:bg-indigo-400;
    @apply text-white dark:text-gray-900;

    &--precreated {
      @apply bg-gray-600 dark:bg-gray-400;
    }
  }

  &__title {
    @apply text-gray-600 dark:text-gray-400;
  }

  &__description {
    @apply text-xs text-gray-500 dark:text-gray-300;
  }

  &__footer {
    @apply w-full;
    @apply flex justify-between items-center;
    @apply gap-2;
  }

  &__label {
    @apply text-base sm:text-lg;

    &--increase {
      @apply text-green-700 dark:text-green-400;
    }

    &--decrease {
      @apply text-red-700 dark:text-red-400;
    }
  }

  &__percent {
    @apply px-2 py-1;
    @apply rounded;
    @apply text-sm;
    @apply bg-gray-100 dark:bg-gray-700;
    @apply text-gray-700 dark:text-gray-300;

    &--increase {
      @apply text-green-700 bg-green-100;
      @apply dark:text-green-400 dark:bg-green-700;
    }

    &--decrease {
      @apply text-red-700 bg-red-100;
      @apply dark:text-red-400 dark:bg-red-700;
    }
  }
}
</style>
