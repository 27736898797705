<template>
  <div class="card-balance">
    <span class="card-balance__name">{{ currentPeriod }}</span>
    <UISkeleton
      v-if="loading"
      variant="dark"
      rounded
      class="card-balance__amount--skeleton"
    />
    <span
      v-else
      class="card-balance__amount blurable-number--md"
      :class="amountClasses"
      >{{ formattedAmount }}</span
    >
    <div class="card-balance__net-worth">
      <UISkeleton
        v-if="loading"
        variant="semi-dark"
        rounded
        class="card-balance__net-worth__amount--skeleton"
      />
      <template v-else>
        <span class="card-balance__net-worth__amount blurable-number">{{
          formattedNetWorth
        }}</span>
        <span class="card-balance__net-worth__caption">NET WORTH</span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, inject } from 'vue'

import { PERIODS_MAP } from './utils/const'

import { numberFormat } from '@/helpers/numbers'

import { useRepositoriesStore } from '@/store/repositories'

import { UISkeleton } from '@ui/skeletons'

type Props = {
  total: number
  netWorth: number
  changeValue?: number
  calculateChange?: boolean
  loading?: boolean
}

const props = defineProps<Props>()

const repositoriesStore = useRepositoriesStore()

const currency = inject<ComputedRef<string>>('currency')

const amountClasses = computed(() => ({
  'card-balance__amount--increase': props.total > 0,
  'card-balance__amount--decrease': props.total < 0,
}))

const formattedAmount = computed(() => {
  let result = props.total ? `${props.total > 0 ? '↑' : '↓'}` : ''
  result += currency?.value
  result += numberFormat(Math.abs(props.total))
  if (props.calculateChange && props.changeValue) {
    result += ` (${Math.abs(props.changeValue)}%)`
  }
  return result
})

const formattedNetWorth = computed(() =>
  numberFormat(Math.abs(props.netWorth), { currency: currency?.value }),
)

const freq = computed(() => repositoriesStore.getCurrentTimeline?.freq)

const currentPeriod = computed(() => PERIODS_MAP[freq.value || 'day'])

defineOptions({
  name: 'UICardBalance',
})
</script>

<style scoped lang="postcss">
.card-balance {
  @apply flex flex-col flex-auto;
  @apply overflow-hidden;

  &__name {
    @apply mb-2 mt-4 mx-6;
    @apply text-xs font-medium;
    @apply text-gray-950 dark:text-gray-50;
  }

  &__amount {
    @apply flex-auto;
    @apply mb-4 mx-6;
    @apply text-3xl;
    @apply text-gray-400 dark:text-gray-500;

    &--skeleton {
      @apply w-2/3 h-6;
      @apply mb-4 mx-6;
    }

    &--increase {
      @apply text-green-700 dark:text-green-300;
    }

    &--decrease {
      @apply text-red-700 dark:text-red-300;
    }
  }

  &__net-worth {
    @apply w-full max-h-14;
    @apply flex items-baseline;
    @apply px-6 py-4 gap-x-2;
    @apply bg-gray-50 dark:bg-gray-850;
    @apply text-gray-500 dark:text-gray-400;

    &__amount {
      &--skeleton {
        @apply w-1/2 h-6;
      }
    }

    &__caption {
      @apply text-xs;
    }
  }
}
</style>
