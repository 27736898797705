export const PERIODS_LIMIT = 15

export const FREQUENCY_VALUES = [
  {
    value: 'day',
    label: 'DAILY',
  },
  {
    value: 'week',
    label: 'WEEKLY',
  },
  {
    value: 'month',
    label: 'MONTHLY',
  },
  {
    value: 'quarter',
    label: 'QUARTERLY',
  },
  {
    value: 'year',
    label: 'YEARLY',
  },
]
