<template>
  <UIButton
    v-bind="{ disabled, label, ...$attrs }"
    class="ui-pagination-button"
    :class="mainClasses"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UIButton } from '@ui/buttons'

type Props = {
  page: number
  pageNo: number
}

type Emits = {
  click: [data: number]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'PaginationButton',
  inheritAttrs: false,
})

const label = computed(() => (props.page === 0 ? '...' : props.page.toString()))
const disabled = computed(() => props.page === 0)

const isCurrent = computed(() => props.page === props.pageNo + 1)

const mainClasses = computed(() =>
  isCurrent.value ? 'ui-pagination-button--current' : undefined,
)

const handleClick = () => {
  if (props.page === props.pageNo + 1 || props.page === 0) return
  emit('click', props.page - 1)
}
</script>

<style scoped>
.ui-pagination-button {
  &--current {
    @apply bg-indigo-100 dark:bg-indigo-900;
    @apply z-10;
  }
}
</style>
