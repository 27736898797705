<template>
  <UILayoutNoData
    class="m-auto"
    :inline="isReadonly"
    :hide-question-icon="isReadonly"
    @click:help="intercom.help(5480491)"
  >
    <template v-if="!isReadonly" #text-description>
      <template v-if="isHasTransactions"
        >No transactions or error calculating analytics <br />for
        {{ stringToLocalDateString(repositoryEnd) }}</template
      >
      <template v-else
        >Get started by creating a new transaction</template
      ></template
    >
    <template v-if="!isReadonly" #default>
      <UIButton
        :to="{ name: ROUTE_NAME.TRANSACTIONS }"
        label="Go to transactions"
        full
      />
    </template>
    <template #text-question>What is a transaction?</template>
  </UILayoutNoData>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { ReadonlyMode } from '@types'

import { ROUTE_NAME } from '@/const'
import { READONLY_MODE } from '@/const/common'

import { stringToLocalDateString } from '@/helpers/dates'

import { useIntercom } from '@/plugins/intercom'

import { UILayoutNoData } from '@ui/core'
import { UIButton } from '@ui/buttons'

import { useRepositoriesStore } from '@/store/repositories'
import { useTransactionsStore } from '@/store/transactions'

const intercom = useIntercom()

const repositoriesStore = useRepositoriesStore()
const transactionsStore = useTransactionsStore()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const repositoryEnd = computed(() => repositoriesStore.getTimelineEnd)

const isHasTransactions = computed(
  () => transactionsStore.initFlag && !!transactionsStore.getList.length,
)
</script>

<script lang="ts">
export default {
  name: 'LayoutNoTransactions',
}
</script>
