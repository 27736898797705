<template>
  <UICard
    ref="cardRef"
    :unwrapped="data.unwrapped"
    class="ui-widget"
    :class="mainClasses"
  >
    <slot />
  </UICard>
</template>

<script setup lang="ts">
import { Ref, computed, inject, useTemplateRef } from 'vue'

import { Widget } from '@types'

import { UICard } from '@ui/core'

type Props = {
  data: Widget
  sticky?: boolean
}

const props = defineProps<Props>()

defineExpose({
  attract() {
    cardRef.value?.attract?.()
  },
})

const flatView = inject<Ref<boolean>>('flatView')

const cardRef = useTemplateRef('cardRef')

const mainClasses = computed(() => ({
  'ui-widget--sticky': props.sticky,
  ...(flatView?.value
    ? { 'ui-widget--wide': true }
    : {
        'ui-widget--wide': props.data.wide,
        'ui-widget--tall': props.data.tall,
      }),
}))
</script>

<script lang="ts">
export default {
  name: 'UIWidget',
}
</script>

<style lang="postcss">
.ui-widget {
  @apply min-h-[20rem];
  @apply flex flex-col;
  @apply relative;

  &--sticky {
    @apply sticky top-0;
    @apply z-40;
  }

  &--wide {
    @apply col-span-2;
  }

  &--tall {
    @apply min-h-[40rem] first:last:min-h-fit;
    @apply row-span-2;
  }

  &.ui-card--unwrapped {
    @apply min-h-0;
  }

  &__caption {
    @apply min-h-[2.5rem];
    @apply relative;
    @apply flex items-center;
    @apply gap-x-2;
    @apply -m-4 mb-2;
    @apply px-4;
    @apply bg-gray-50 text-gray-500;
    @apply dark:bg-gray-850 dark:text-gray-400;
    @apply dark:border-b dark:border-gray-800;
    @apply rounded-md rounded-b-none;
    @apply transition-colors duration-700;

    .ui-card--attract & {
      @apply bg-indigo-50 dark:bg-indigo-800;
    }
  }

  &__metrics {
    @apply flex flex-col sm:flex-row items-center justify-between;
    @apply gap-4 sm:gap-2 mb-4 sm:mb-2;
  }

  &__catchword {
    @apply ml-auto;
    @apply font-light;
    @apply text-xs;
    @apply uppercase;
    @apply whitespace-nowrap;
  }

  .e-chart {
    @apply flex-auto;
  }
}
</style>
