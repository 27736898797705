<template>
  <div class="ui-alert" :class="mainClasses">
    <slot name="before" />
    <div class="ui-alert__message">
      <slot />
    </div>
    <button
      v-if="closeable"
      class="ui-alert__close-button"
      @click="handleClickRemove"
    >
      <span class="sr-only">Close</span>
      <XMarkIcon class="ui-alert__close-icon" aria-hidden="true" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { AlertType } from './utils/types'

import { XMarkIcon } from '@heroicons/vue/20/solid'

type Props = {
  type?: AlertType
  closeable?: boolean
}

type Emits = {
  remove: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'UIAlert',
})

const mainClasses = computed(() => `ui-alert--${props.type}`)

const handleClickRemove = () => {
  emit('remove')
}
</script>

<style>
.ui-alert {
  @apply flex items-center;
  @apply gap-2 p-4;
  @apply md:rounded-md;

  &--progress,
  &--info {
    @apply bg-indigo-50 text-indigo-800;
    @apply dark:bg-indigo-900 dark:text-indigo-300;
  }

  &--success {
    @apply bg-green-50 text-green-800;
    @apply dark:bg-green-900 dark:text-green-300;
  }

  &--error {
    @apply bg-red-50 text-red-800;
    @apply dark:bg-red-900 dark:text-red-300;
  }

  &--warn {
    @apply bg-yellow-50 text-yellow-800;
    @apply dark:bg-yellow-900 dark:text-yellow-300;
  }

  &__message {
    @apply text-sm font-medium;
  }

  &__close-button {
    @apply ml-auto;
    @apply bg-transparent rounded-md inline-flex;
    @apply text-gray-400 hover:text-gray-500;
    @apply dark:text-gray-200 dark:hover:text-gray-300;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  &__close-icon {
    @apply h-5 w-5;
  }
}
</style>
