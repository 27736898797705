<template>
  <div class="ui-tabs" v-bind="$attrs">
    <div class="ui-tabs__wrapper" :class="tabsWrapperClasses">
      <nav class="ui-tabs__nav" aria-label="Tabs">
        <template v-for="tab in tabs" :key="tab.name">
          <div v-if="!noSeparated" class="ui-tabs__divider"></div>
          <router-link
            v-if="tab.route"
            :to="tab.route"
            exact
            exact-active-class="ui-tabs__item--active"
            class="ui-tabs__item"
          >
            {{ tab.name }}
          </router-link>
          <div
            v-else
            class="ui-tabs__item"
            :class="{
              'ui-tabs__item--active': modelValue === tab.name,
            }"
            @click="modelValue = tab.name"
          >
            {{ tab.name }}
          </div>
        </template>
      </nav>
      <div v-if="!noSeparated && tabs.length > 0" class="ui-tabs__divider" />
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import { TabsItem } from './utils/types'

type Props = {
  tabs: TabsItem[]
  bottomBorder?: boolean
  noSeparated?: boolean
}

const props = defineProps<Props>()

const modelValue = defineModel<string>()

const route = useRoute()

const tabsWrapperClasses = computed(() => ({
  'ui-tabs__wrapper--with-border': props.bottomBorder,
}))
</script>

<style>
.ui-tabs {
  &__wrapper {
    @apply h-full flex w-full items-center gap-x-4;

    &--with-border {
      @apply border-b border-gray-200 dark:border-gray-700;
    }
  }

  &__nav {
    @apply -mb-px h-full flex items-center gap-x-4;
    @apply border-transparent border-t-2;
  }

  &__item {
    @apply h-full flex items-center py-4 px-1;
    @apply text-gray-500 hover:text-gray-700 hover:border-gray-300;
    @apply dark:text-gray-400 dark:hover:text-gray-300 dark:hover:border-gray-500;
    @apply border-transparent border-b-2;
    @apply whitespace-nowrap font-medium text-sm cursor-pointer;

    &--active {
      @apply border-indigo-500 dark:border-indigo-600 text-indigo-600 dark:text-indigo-500;
    }
  }

  &__divider {
    @apply block;
    @apply h-6 border-l;
    @apply border-gray-200 dark:border-gray-700;
  }
}
</style>
