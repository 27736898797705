<template>
  <div class="ui-subscription">
    <div class="ui-subscription__info">
      <h3 class="ui-subscription__plan">
        Your current plan is
        <span class="ui-subscription__name">{{ planName }}</span>
      </h3>
      <div v-if="isTrial" class="ui-subscription__trial">
        <div class="ui-subscription__trial-message">
          You are on a free trial period
        </div>
      </div>
      <div class="ui-subscription__description">
        {{ description }}
        <small>Expire{{ !daysLeft ? 'd' : 's' }} on {{ endDate }}</small>
      </div>
      <div class="ui-subscription__included">
        <h4 class="ui-subscription__included-caption">What’s included</h4>
        <div class="ui-subscription__included-line" />
      </div>
      <SubscriptionPlanFeatures
        v-bind="{ features }"
        class="ui-subscription__features"
      />
    </div>
    <div v-if="hasEarlyAccess" class="ui-subscription__price">
      <p class="ui-subscription__price-caption">Price</p>
      <p class="ui-subscription__price-value">
        {{ priceValue }}<small>/month</small>
      </p>
      <div class="ui-subscription__buttons">
        <UIButton
          v-bind="{ loading }"
          :disabled="disabled || !isWaitingForPaid"
          label="Payment"
          full
          @click="handleClickPayment"
        />
        <UIButton
          v-bind="{ disabled }"
          label="Change plan"
          variant="secondary"
          fill="light"
          full
          @click="handleClickChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, ref } from 'vue'
import { DateTime } from 'luxon'

import { useUserSettingsStore } from '@/store/user/settings'

import { UserSubscription, UserSubscriptionStatus } from '@types'

import { stringToLocalDateString } from '@/helpers/dates'
import { numberFormat } from '@/helpers/numbers'

import SubscriptionPlanFeatures from './SubscriptionPlanFeatures.vue'
import { UIButton } from '@ui/buttons'

type Props = {
  subscription: UserSubscription
}

type Emits = {
  'click:payment': [callback: () => void]
  'click:change': []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'UISubscription',
})

const userSettingsStore = useUserSettingsStore()

const currency = inject<ComputedRef<string>>('currency')

const disabled = ref(false)
const loading = ref(false)

const planSchema = computed(() => props.subscription.plan.plan_schema)

const planName = computed(() => planSchema.value.name)

const endDate = computed(() =>
  stringToLocalDateString(props.subscription.end_date),
)

const description = computed(() => planSchema.value.description)

const isTrial = computed(
  () => props.subscription.status === UserSubscriptionStatus.IN_TRIAL,
)
const isWaitingForPaid = computed(
  () =>
    props.subscription.status === UserSubscriptionStatus.WAITING_FOR_PAYMENT ||
    !daysLeft.value,
)

const hasEarlyAccess = computed(
  () => !!userSettingsStore.getEarlyAccess || __IS_LOCAL__,
)

const priceValue = computed(() =>
  numberFormat(props.subscription.plan.price, {
    currency: currency?.value,
    fractionDigits: 2,
  }),
)

const daysLeft = computed(() => {
  const date1 = DateTime.fromISO(props.subscription.end_date)
  const date2 = DateTime.now()

  const diff = date1.diff(date2, ['months', 'days'], {
    conversionAccuracy: 'longterm',
  })

  const result = []

  if (diff.months > 0) {
    result.push(`${diff.months} months`)
  }

  if (diff.days > 0) {
    const number = Math.ceil(diff.days)
    result.push(`${number} day${number > 1 ? 's' : ''}`)
  }

  return result.length && `Left ${result.join(' ')}`
})

const features = computed(() => planSchema.value.features || [])

const handleClickPayment = () => {
  loading.value = true
  disabled.value = true
  emit('click:payment', () => {
    loading.value = false
    disabled.value = false
  })
}

const handleClickChange = () => {
  emit('click:change')
}
</script>

<style>
.ui-subscription {
  @apply flex flex-col md:flex-row;
  @apply gap-4 p-4;
  @apply rounded-3xl;
  @apply ring-1 ring-inset ring-gray-200 dark:ring-gray-600;

  &__info {
    @apply flex flex-col;
    @apply gap-2 py-2 px-2 sm:px-6;
  }

  &__plan {
    @apply text-2xl font-bold tracking-tight;
    @apply text-gray-900 dark:text-gray-100;
  }

  &__name {
    @apply bg-indigo-500;
    @apply py-0.5 px-2 ml-1;
    @apply rounded-full;
    @apply text-white uppercase;
    @apply text-base font-normal tracking-normal;
  }

  &__trial,
  &__description {
    @apply text-base leading-normal;
    @apply text-gray-600 dark:text-gray-400;

    small {
      @apply text-sm;
    }
  }

  &__trial-message {
    @apply text-green-700 dark:text-green-400;
  }
  &__included {
    @apply flex items-center;
    @apply gap-x-4;
  }

  &__included-caption {
    @apply flex-none;
    @apply text-sm font-semibold leading-6;
    @apply text-indigo-600 dark:text-indigo-400;
  }

  &__included-line {
    @apply h-px;
    @apply flex-auto;
    @apply bg-gray-100 dark:bg-gray-600;
  }

  &__features {
    @apply max-w-96;
  }

  &__price {
    @apply md:w-1/2 shrink-0;
    @apply flex flex-col items-center justify-center;
    @apply gap-4 py-10 lg:py-16 px-6;
    @apply bg-gray-50 dark:bg-gray-850;
    @apply rounded-2xl;
    @apply ring-1 ring-inset ring-gray-900/5 dark:ring-gray-800;
  }

  &__price-caption {
    @apply text-base font-semibold;
    @apply text-gray-600 dark:text-gray-400;
  }

  &__price-value {
    @apply text-5xl font-bold tracking-tight;
    @apply text-gray-600 dark:text-gray-400;

    small {
      @apply font-normal tracking-normal;
      @apply text-lg;
    }
  }

  &__buttons {
    @apply w-full;
    @apply flex flex-col;
    @apply gap-2 mt-2;
  }
}
</style>
